'use strict';

$(function () {
  scrollTo();
});

function scrollTo() {
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top - 10
    }, 1200);
  });
}