'use strict';

// Closest Polyfilly for IE
function closestPolyfill() {
  (function (ElementProto) {
    if (typeof ElementProto.matches !== 'function') {
      ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector || function matches(selector) {
        var element = this;
        var elements = (element.document || element.ownerDocument).querySelectorAll(selector);
        var index = 0;

        while (elements[index] && elements[index] !== element) {
          ++index;
        }

        return Boolean(elements[index]);
      };
    }

    if (typeof ElementProto.closest !== 'function') {
      ElementProto.closest = function closest(selector) {
        var element = this;

        while (element && element.nodeType === 1) {
          if (element.matches(selector)) {
            return element;
          }

          element = element.parentNode;
        }

        return null;
      };
    }
  })(window.Element.prototype);
}
closestPolyfill();

$(function () {
  openModal();
});

function openModal() {
  // Check device
  var mobileCheck = new RegExp('Android|webOS|iPhone|iPad|' + 'BlackBerry|Windows Phone|' + 'Opera Mini|IEMobile|Mobile', 'i');

  var eventType = '';

  if (mobileCheck.test(navigator.userAgent)) {
    eventType = 'touchstart';
  } else {
    eventType = 'click';
  }

  // Begin modal
  var modals = document.getElementsByClassName('modal-trigger');

  for (var i = 0; i < modals.length; i++) {
    modals[i].addEventListener(eventType, function (e) {
      e.preventDefault();
      e.stopPropagation();

      var selectModal = this.getAttribute('data-modal').substr(1);

      document.getElementById(selectModal).setAttribute('class', 'modal-container modal-active');

      document.getElementById(selectModal).getElementsByClassName('modalbox')[0].setAttribute('class', 'modalbox grow');

      // document.getElementById('wrapper').setAttribute('class', 'blur')
    });
  }

  // On click exit, hide modal
  $('.exit').on('click touchstart', function () {
    $('.modal-active').find('.modalbox').removeClass('reversegrow');
    $('.modal-active').find('.modalbox').addClass('reversegrow');
    $('.modal-container').removeClass('modal-active');
  });

  // On outside click, hide modal
  document.addEventListener(eventType, function (e) {
    if (document.getElementsByClassName('modal-active')[0]) {
      var modalOpen = document.getElementsByClassName('modal-active');
      for (var i = 0; i < modalOpen.length; i++) {
        var clickModal = modalOpen[i].contains(e.target.closest('.modalbox'));

        if (clickModal === false) {
          document.getElementsByClassName('modal-active')[0].getElementsByClassName('modalbox')[0].setAttribute('class', 'modalbox reversegrow');

          document.getElementsByClassName('modal-active')[0].setAttribute('class', 'modal-container');
        }
      }
    }
  });
}